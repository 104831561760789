import * as React from 'react';
import FormWizard from './FormWizard';
import ProductCard from '../../../components/subscription/ProductCard';
import PropTypes from 'prop-types';
import { connect, FormikProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { FormWizardContext } from './FormWizard';
import { graphql, StaticQuery } from 'gatsby';
import { PricingPlan, PricingPlanBuilder } from '../../../components/subscription/PricingPlan';
import { Product, ProductBuilder } from '../../../components/subscription/Product';
import { ProductsQuery } from '../../../queries/ProductsQuery';
import { Subscription, SubscriptionBuilder } from '../../../components/subscription/Subscription';

class PureSubscriptionForm extends React.Component<{ formik?: FormikProps<any>; products?: Array<Product> }> {
  static contextType: React.Context<FormWizard | {}> = FormWizardContext

  constructor(props) {
    super(props)
  }

  private selectPlan(plan: PricingPlan): void {
    if (!(plan instanceof PricingPlan)) throw new TypeError('Plan is not an instance of Plan.')
    const subscription: Subscription = new SubscriptionBuilder().setPlan(plan).build()
    this.props.formik!.setFieldValue('user.tenants[0].subscription', subscription, true)
    this.context.nextHandler()
  }

  public componentDidMount() {
    this.context.setState({ showNextButton: false })
  }

  public componentWillUnmount() {
    this.context.setState({ showNextButton: true })
  }

  public render(): React.ReactNode {
    return (
      <>
        {this.props.formik!.values && (
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-12">
              <h1 className="mdc-typography--headline2">
                <FormattedMessage id="signup.subscription.title" defaultMessage="Wählen Sie ein Abonnement und starten Sie ihren 30 Tage Test." />
              </h1>
            </div>

            {/* Basic */}
            <div className="mdc-layout-grid__cell--span-4">
              <ProductCard
                product={this.props.products![0]}
                description="Grundlage für jeden Ackerbau-Betrieb, um im Digital-Farming zu beginnen."
                cta={<FormattedMessage id="signup.subscription.plan.cta" defaultMessage="Auswählen" />}
                outlined={true}
                selectedPlan={this.props.formik!.values.user.tenants[0].subscription ? this.props.formik!.values.user.tenants[0].subscription.plan : undefined}
                onSelectCallback={this.selectPlan.bind(this)}
              />
            </div>

            {/* Standard */}
            <div className="mdc-layout-grid__cell--span-4">
              <ProductCard
                product={this.props.products![1]}
                description="Für Betriebe, die mit Hilfe von Satelliten-Daten den Ertrag steigern wollen."
                cta={<FormattedMessage id="signup.subscription.plan.cta" defaultMessage="Auswählen" />}
                outlined={true}
                selectedPlan={this.props.formik!.values.user.tenants[0].subscription ? this.props.formik!.values.user.tenants[0].subscription.plan : undefined}
                onSelectCallback={this.selectPlan.bind(this)}
              />
            </div>

            {/* Pro */}
            <div className="mdc-layout-grid__cell--span-4">
              <ProductCard
                product={this.props.products![2]}
                description="Erhalten Sie Handlungsempfehlungen und vermarkten Sie die Ernte."
                cta={<FormattedMessage id="signup.subscription.plan.cta" defaultMessage="Auswählen" />}
                outlined={true}
                selectedPlan={this.props.formik!.values.user.tenants[0].subscription ? this.props.formik!.values.user.tenants[0].subscription.plan : undefined}
                onSelectCallback={this.selectPlan.bind(this)}
              />
            </div>

            <input hidden type="text" name="user.tenants[0].subscription" onChange={this.props.formik!.handleChange} />
          </div>
        )}
      </>
    )
  }
}

class SubscriptionForm extends React.Component<{ children?: JSX.Element[] | JSX.Element }> {
  static propTypes: any

  public context: any

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            site {
              ...ProductsQuery
            }
          }
        `}
        render={data => {
          const products: Array<Product> = []

          for (let i = 0; i < data.site.siteMetadata.products.length; i++) {
            const product: ProductBuilder = new ProductBuilder().setName(data.site.siteMetadata.products[i].name)

            for (let p = 0; p < data.site.siteMetadata.products[i].plans.length; p++) {
              if (!data.site.siteMetadata.products[i].plans[p]) continue
              const plan: PricingPlan = new PricingPlanBuilder()
                .setAcreage(data.site.siteMetadata.products[i].plans[p].acreage)
                .setContractType(data.site.siteMetadata.products[i].plans[p].contractType)
                .setCurrency(data.site.siteMetadata.products[i].plans[p].currency)
                .setPrice(data.site.siteMetadata.products[i].plans[p].price)
                .setStripePlanId(data.site.siteMetadata.products[i].plans[p].stripePlanId)
                .build()
              product.plans.push(plan)
            }

            products.push(product.build())
          }
          return <PureSubscriptionForm {...this.props} products={products} />
        }}
      />
    )
  }
}

SubscriptionForm.propTypes = {
  children: PropTypes.node,
}

export default connect(SubscriptionForm as React.ComponentType<any>)
